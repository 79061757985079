import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _3c0d1650 = () => interopDefault(import('../pages/aboutus.vue' /* webpackChunkName: "pages/aboutus" */))
const _5a0e43e3 = () => interopDefault(import('../pages/cipp-offer/index.vue' /* webpackChunkName: "pages/cipp-offer/index" */))
const _00edd3a8 = () => interopDefault(import('../pages/help.vue' /* webpackChunkName: "pages/help" */))
const _d0bb5c9a = () => interopDefault(import('../pages/verify-email.vue' /* webpackChunkName: "pages/verify-email" */))
const _585efea2 = () => interopDefault(import('../pages/business/analytics.vue' /* webpackChunkName: "pages/business/analytics" */))
const _1c49ae1c = () => interopDefault(import('../pages/business/communication.vue' /* webpackChunkName: "pages/business/communication" */))
const _1c48e591 = () => interopDefault(import('../pages/business/membersupport.vue' /* webpackChunkName: "pages/business/membersupport" */))
const _28e0d92e = () => interopDefault(import('../pages/dashboard/combine.vue' /* webpackChunkName: "pages/dashboard/combine" */))
const _fb2ff03c = () => interopDefault(import('../pages/dashboard/documents.vue' /* webpackChunkName: "pages/dashboard/documents" */))
const _2fb8a441 = () => interopDefault(import('../pages/dashboard/landing.vue' /* webpackChunkName: "pages/dashboard/landing" */))
const _75efcf3e = () => interopDefault(import('../pages/dashboard/nextsteps.vue' /* webpackChunkName: "pages/dashboard/nextsteps" */))
const _4203ef0c = () => interopDefault(import('../pages/dashboard/report.vue' /* webpackChunkName: "pages/dashboard/report" */))
const _046ea7e9 = () => interopDefault(import('../pages/dashboard/reports.vue' /* webpackChunkName: "pages/dashboard/reports" */))
const _ca1d3c82 = () => interopDefault(import('../pages/dashboard/retirement.vue' /* webpackChunkName: "pages/dashboard/retirement" */))
const _600f3243 = () => interopDefault(import('../pages/dashboard/review-choices.vue' /* webpackChunkName: "pages/dashboard/review-choices" */))
const _4165cde2 = () => interopDefault(import('../pages/dashboard/selected-funds.vue' /* webpackChunkName: "pages/dashboard/selected-funds" */))
const _65b398ac = () => interopDefault(import('../pages/dashboard/selfhelpdirectory.vue' /* webpackChunkName: "pages/dashboard/selfhelpdirectory" */))
const _52756c69 = () => interopDefault(import('../pages/dashboard/settings.vue' /* webpackChunkName: "pages/dashboard/settings" */))
const _825b47f6 = () => interopDefault(import('../pages/dashboard/shortlist-funds.vue' /* webpackChunkName: "pages/dashboard/shortlist-funds" */))
const _179db102 = () => interopDefault(import('../pages/onboarding/annuity-options.vue' /* webpackChunkName: "pages/onboarding/annuity-options" */))
const _0c74acf0 = () => interopDefault(import('../pages/onboarding/authorization.vue' /* webpackChunkName: "pages/onboarding/authorization" */))
const _37472f1f = () => interopDefault(import('../pages/onboarding/checkout.vue' /* webpackChunkName: "pages/onboarding/checkout" */))
const _1c9a7ba6 = () => interopDefault(import('../pages/onboarding/employment-info.vue' /* webpackChunkName: "pages/onboarding/employment-info" */))
const _6ef005da = () => interopDefault(import('../pages/onboarding/final-salary.vue' /* webpackChunkName: "pages/onboarding/final-salary" */))
const _4f2a229d = () => interopDefault(import('../pages/onboarding/fund-selection.vue' /* webpackChunkName: "pages/onboarding/fund-selection" */))
const _8d30da40 = () => interopDefault(import('../pages/onboarding/login.vue' /* webpackChunkName: "pages/onboarding/login" */))
const _5334a54a = () => interopDefault(import('../pages/onboarding/login-error.vue' /* webpackChunkName: "pages/onboarding/login-error" */))
const _070de22a = () => interopDefault(import('../pages/onboarding/other-pot-addition.vue' /* webpackChunkName: "pages/onboarding/other-pot-addition" */))
const _6a8a7e7a = () => interopDefault(import('../pages/onboarding/pension-details.vue' /* webpackChunkName: "pages/onboarding/pension-details" */))
const _07639046 = () => interopDefault(import('../pages/onboarding/pension-provider-by-name.vue' /* webpackChunkName: "pages/onboarding/pension-provider-by-name" */))
const _1e6d18dc = () => interopDefault(import('../pages/onboarding/personal-info.vue' /* webpackChunkName: "pages/onboarding/personal-info" */))
const _75e76dd4 = () => interopDefault(import('../pages/onboarding/redeem-voucher.vue' /* webpackChunkName: "pages/onboarding/redeem-voucher" */))
const _9b0a561c = () => interopDefault(import('../pages/onboarding/reset-password.vue' /* webpackChunkName: "pages/onboarding/reset-password" */))
const _ba69aa8c = () => interopDefault(import('../pages/onboarding/success.vue' /* webpackChunkName: "pages/onboarding/success" */))
const _11d7889b = () => interopDefault(import('../pages/onboarding/suez-info.vue' /* webpackChunkName: "pages/onboarding/suez-info" */))
const _0b9e0d6e = () => interopDefault(import('../pages/personal/account.vue' /* webpackChunkName: "pages/personal/account" */))
const _10aacaa0 = () => interopDefault(import('../pages/personal/nextsteps.vue' /* webpackChunkName: "pages/personal/nextsteps" */))
const _5090048d = () => interopDefault(import('../pages/personal/retirement.vue' /* webpackChunkName: "pages/personal/retirement" */))
const _7cf5c588 = () => interopDefault(import('../pages/blog/_slug.vue' /* webpackChunkName: "pages/blog/_slug" */))
const _76c39ff7 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/aboutus",
    component: _3c0d1650,
    name: "aboutus"
  }, {
    path: "/cipp-offer",
    component: _5a0e43e3,
    name: "cipp-offer"
  }, {
    path: "/help",
    component: _00edd3a8,
    name: "help"
  }, {
    path: "/verify-email",
    component: _d0bb5c9a,
    name: "verify-email"
  }, {
    path: "/business/analytics",
    component: _585efea2,
    name: "business-analytics"
  }, {
    path: "/business/communication",
    component: _1c49ae1c,
    name: "business-communication"
  }, {
    path: "/business/membersupport",
    component: _1c48e591,
    name: "business-membersupport"
  }, {
    path: "/dashboard/combine",
    component: _28e0d92e,
    name: "dashboard-combine"
  }, {
    path: "/dashboard/documents",
    component: _fb2ff03c,
    name: "dashboard-documents"
  }, {
    path: "/dashboard/landing",
    component: _2fb8a441,
    name: "dashboard-landing"
  }, {
    path: "/dashboard/nextsteps",
    component: _75efcf3e,
    name: "dashboard-nextsteps"
  }, {
    path: "/dashboard/report",
    component: _4203ef0c,
    name: "dashboard-report"
  }, {
    path: "/dashboard/reports",
    component: _046ea7e9,
    name: "dashboard-reports"
  }, {
    path: "/dashboard/retirement",
    component: _ca1d3c82,
    name: "dashboard-retirement"
  }, {
    path: "/dashboard/review-choices",
    component: _600f3243,
    name: "dashboard-review-choices"
  }, {
    path: "/dashboard/selected-funds",
    component: _4165cde2,
    name: "dashboard-selected-funds"
  }, {
    path: "/dashboard/selfhelpdirectory",
    component: _65b398ac,
    name: "dashboard-selfhelpdirectory"
  }, {
    path: "/dashboard/settings",
    component: _52756c69,
    name: "dashboard-settings"
  }, {
    path: "/dashboard/shortlist-funds",
    component: _825b47f6,
    name: "dashboard-shortlist-funds"
  }, {
    path: "/onboarding/annuity-options",
    component: _179db102,
    name: "onboarding-annuity-options"
  }, {
    path: "/onboarding/authorization",
    component: _0c74acf0,
    name: "onboarding-authorization"
  }, {
    path: "/onboarding/checkout",
    component: _37472f1f,
    name: "onboarding-checkout"
  }, {
    path: "/onboarding/employment-info",
    component: _1c9a7ba6,
    name: "onboarding-employment-info"
  }, {
    path: "/onboarding/final-salary",
    component: _6ef005da,
    name: "onboarding-final-salary"
  }, {
    path: "/onboarding/fund-selection",
    component: _4f2a229d,
    name: "onboarding-fund-selection"
  }, {
    path: "/onboarding/login",
    component: _8d30da40,
    name: "onboarding-login"
  }, {
    path: "/onboarding/login-error",
    component: _5334a54a,
    name: "onboarding-login-error"
  }, {
    path: "/onboarding/other-pot-addition",
    component: _070de22a,
    name: "onboarding-other-pot-addition"
  }, {
    path: "/onboarding/pension-details",
    component: _6a8a7e7a,
    name: "onboarding-pension-details"
  }, {
    path: "/onboarding/pension-provider-by-name",
    component: _07639046,
    name: "onboarding-pension-provider-by-name"
  }, {
    path: "/onboarding/personal-info",
    component: _1e6d18dc,
    name: "onboarding-personal-info"
  }, {
    path: "/onboarding/redeem-voucher",
    component: _75e76dd4,
    name: "onboarding-redeem-voucher"
  }, {
    path: "/onboarding/reset-password",
    component: _9b0a561c,
    name: "onboarding-reset-password"
  }, {
    path: "/onboarding/success",
    component: _ba69aa8c,
    name: "onboarding-success"
  }, {
    path: "/onboarding/suez-info",
    component: _11d7889b,
    name: "onboarding-suez-info"
  }, {
    path: "/personal/account",
    component: _0b9e0d6e,
    name: "personal-account"
  }, {
    path: "/personal/nextsteps",
    component: _10aacaa0,
    name: "personal-nextsteps"
  }, {
    path: "/personal/retirement",
    component: _5090048d,
    name: "personal-retirement"
  }, {
    path: "/blog/:slug?",
    component: _7cf5c588,
    name: "blog-slug"
  }, {
    path: "/",
    component: _76c39ff7,
    name: "index"
  }],

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
